<script>
  import { onMount } from "svelte";
  import { Confetti } from "svelte-confetti";
  import { Link } from "svelte-routing";
  import Toolbar from "./Toolbar.svelte";
  import Puzzle from "./Puzzle.svelte";
  import Clues from "./Clues.svelte";
  import CompletedMessage from "./CompletedMessage.svelte";
  import createClues from "./helpers/createClues.js";
  import createCells from "./helpers/createCells.js";
  import validateClues from "./helpers/validateClues.js";
  import { fromPairs } from "./helpers/utils.js";
  import checkMobile from "./helpers/checkMobile";
  import themeStyles from "./helpers/themeStyles.js";
  import dataNYTMini from "../example/data/nyt-mini.json";
  import Config from "../src/config/config";
  import axios from "axios";
  import { navigate } from "svelte-routing";
  import Loader from "./Loader.svelte";
  // let data = dataNYTMini;
  let data = [];
  // export let actions = ["clear", "reveal", "check"];
  const isMobile = checkMobile();
  let showKeyboard = false;
  if (isMobile) {
    showKeyboard = true;
  }
  export let theme = "classic";
  export let revealDuration = 1000;
  export let breakpoint = 720;
  export let revealed = false;
  export let disableHighlight = false;
  export let showCompleteMessage = true;
  export let showConfetti = false;
  export let keyboardStyle = "";
  let width = 0;
  let focusedDirection = "across";
  let focusedCellIndex = 0;
  let isRevealing = false;
  let isLoaded = false;
  let isChecking = false;
  let revealTimeout;
  let clueCompletion;
  let puzzleData;
  let email_signup_url = '';
  let isKeepTryingButtonValue = null;
  let originalClues = [];
  let validated = [];
  let clues = [];
  let cells = [];
  let isSaveData = true;
  let isWordConnectionsShow = false;
  let dontAskAgainInput = false; 
  let isIncognitoWindowError = false;
  let database;
  let dontShowAgainValue = false;
  let showPermissionPopup = false;
  // let isIpadDevice = navigator.userAgent.match(/iPad|iPod/i) ? true : false;
  // let isIpadDevice =  false;

  //here i am checking if the query paramter have embebed version parameter

  const urlParams = new URLSearchParams(window?.location?.search);
  const isEmbeded = urlParams.has('version');


  const onDataUpdate = () => {
    if (data.length) {
      crossSolvedPuzzle = data.filter((item) => {
        return item.news_url !== null && item.news_url !== "" ;
      });
    }
    originalClues = createClues(data);
    validated = validateClues(originalClues);
    clues = originalClues.map((d) => ({ ...d }));
    cells = createCells(originalClues);
    reset();
  };
  const saveToDatabase = async (data) => {
    const response = await axios.post(`${api_path}save-puzzle`, data);
    const res = response.data;
    return res;
  };

    // Function to handle the message event
  function handleMessage(event) {
    // console.log('Handle Message');
    // console.log(event);
    // console.log(event.data);
    // console.log(event.origin);
    if (event.origin === 'https://harwsolutions.com') {
      // alert('Received data from iframe:');
      // console.log('Received data from iframe:', event.data);
    }
  }


  const onStatusUpdate = (percentCorrect) => {

    try {


      let isWon = 'no';
      if (percentCorrect == 1) {
        toolBarEvents.stop();
        // if(isEmbeded == false){
          localStorage.setItem("start",true);
          //remove localstorage
          localStorage.removeItem('isKeepTrying');

          isWon = localStorage.getItem('w');
        // }
        

        loading = true;
        disabled = true;
        playTime = toolBarEvents.getTime();
        // if(playTime == undefined){
        //   playTime = '00:00';
        // }
        //  console.log(isWon);

        if(isWon == null || isWon == 'no'){
          isComplete = false;
          showCompleteMessage = false;
          // alert('save to databse');
          //it means user already have not won and its first time so we need to store it in database
          let response = saveToDatabase({
          status: 1,
          time: playTime,
          puzzle_id: puzzleDetail.puzzle_id,
          }).then((res) => {
            if (res.success) {
              getValueFromIndexedDb();
              const dontShowAgainCookie = getValueFromCookies('dontShowAgain');
              loading = false;
              // localStorage.getItem("dontAskAgainInput") === null
              // let dontAskAgainChecked = localStorage.getItem('dontAskAgainInput');
              if(localStorage.getItem("dontAskAgainInput") === null && email_signup_url==''){
                isWordConnectionsShow = true;
                
              }else if(localStorage.getItem("dontAskAgainInput") === null && email_signup_url!=''){
                isWordConnectionsShow = false;
               
              }else if(localStorage.getItem("dontAskAgainInput")){
                isWordConnectionsShow = true;
                
              }else if(dontShowAgainValue == true){
    
                isWordConnectionsShow = true;
              }else if(dontShowAgainCookie == 'true'){
                isWordConnectionsShow = true;
                // alert('cookie = '+dontShowAgainCookie);
              }
              
              setTimeout(function() { 

                showConfetti = true;
                isComplete = true;
              
                showCompleteMessage = true;
              }, 1000);
       
                //w means won and y means yes;
                localStorage.setItem('w','y');
                //I commented below
                // showKeyboard = false;
            

            }
          });
        }else{
          loading = false;
          isComplete = true;
        }

        // let dontAskAgainChecked = localStorage.getItem('dontAskAgainInput');
        if(localStorage.getItem("dontAskAgainInput") != null){
          isWordConnectionsShow = true;
          // alert('localStorage = ' + localStorage.getItem("dontAskAgainInput"));
        }
        if(sessionStorage.getItem("dontAskAgainInput") != null){
          isWordConnectionsShow = true;
          // alert('sessionStorage = ' + sessionStorage.getItem("dontAskAgainInput"));
        }
        getValueFromIndexedDb();
        if(dontShowAgainValue){
          isWordConnectionsShow = true;
          // alert('indexedDB = '+dontShowAgainValue);
        }
        const dontShowAgainCookie = getValueFromCookies('dontShowAgain');
        if(dontShowAgainCookie == 'true'){
          isWordConnectionsShow = true;
          // alert('cookie = '+dontShowAgainCookie);
        }

        // alert('authorizeStorageAccess');
        if(showPermissionPopup === true){
          authorizeStorageAccess();
        }


        // console.log(isWordConnectionsShow);
        return true;
      } else {
        return false;
      }
    }catch(err){
      //local storage not working
      isIncognitoWindowError = true;
      // window.open('https://newscrossword.org/'+url, '_parent').focus();
      loading = false;
      return false;
    }
    
  };

  const IsNotCompleted = (percentNotCorrect, percentCorrect) => {
    try {

     isKeepTryingButtonValue = localStorage.getItem('isKeepTrying');
   
   
      if(isKeepTryingButtonValue == null){
          isKeepTryingButtonValue = 'true';
      }

    //its just for showing the popup
    let isTryAgainPopup = localStorage.getItem('isTryAgainPopup');
   
      if (percentNotCorrect && percentCorrect !== 1 && isKeepTryingButtonValue == 'true') {
        if (toolBarEvents.length || toolBarEvents!='') {
          toolBarEvents.stop();
          // if(isEmbeded == false){
            localStorage.setItem("start",true);
          // }
          loading = true;
          disabled = true;
          playTime = toolBarEvents.getTime();
          if(playTime==undefined){
            playTime = '00:00';
          }

          // console.log(playTime);
          let response = saveToDatabase({
            status: 0,
            time: playTime,
            puzzle_id: puzzleDetail.puzzle_id,
          }).then((res) => {
            if (res.success) {
              // alert('ok');
              loading = false;
              isInCompleted = true;
              isClosePopup = true;
              // if(isEmbeded == false){
              localStorage.setItem('isKeepTrying','false');
              localStorage.setItem('isTryAgainPopup','show');
              //I commented below
              // showKeyboard = false;
              // }
            }
          });
        }
      }else if(isKeepTryingButtonValue != 'true' && isTryAgainPopup =='show'){
        isInCompleted = true;
        isClosePopup = true;
        //I commented below
        // showKeyboard = false;
        return true;
      } else {
        return false;
      }
    } catch( e ) {
       isIncognitoWindowError = true;
      // window.open('https://newscrossword.org/'+url, '_parent').focus();
      loading = false;
      return false;
    }
    
  };
  

  $: data, onDataUpdate();
  $: focusedCell = cells[focusedCellIndex] || {};
  $: cellIndexMap = fromPairs(cells.map((cell) => [cell.id, cell.index]));
  $: percentCorrect =
    cells.filter((d) => d.answer === d.value).length / cells.length;
  $: percentNotCorrect =
    cells.filter((d) => d.value !== "").length == cells.length;
  $: isComplete = onStatusUpdate(percentCorrect);
  $: isInCompleted = IsNotCompleted(percentNotCorrect, percentCorrect);
  $: isDisableHighlight = isComplete && disableHighlight;
  $: cells, (clues = checkClues());
  $: cells, (revealed = !clues.filter((d) => !d.isCorrect).length);
  $: stacked = width < breakpoint;
  $: inlineStyles = themeStyles[theme];
  $: loading = false;
  $: crossSolvedPuzzle = [];
  $: stylePuzzle = [];
  $: puzzleDetail = [];
  $: is_puzzle_show = false;
  $: show_alert = false;
  $: playTime = "";
  $: disabled = false;
  $: toolBarEvents = [];
  $: days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  $: months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  export let url;

  const api_path = Config.api_path;
  const changeDateFormat = (date) => {
    if (date) {
      var st = date;
      var dt = new Date(st);
      if (dt) {
        const dateFormat = `${days[dt.getDay()]}, ${
          months[dt.getMonth()]
        } ${dt.getDate()}, ${dt.getFullYear()}`;
        return dateFormat;
      }
    }
    return date;
  };


  // Function to get the stored value from the database
  const getValueFromIndexedDb = async () => {
    // const transaction = database.transaction(['myStore'], 'readonly');
    // const store = transaction.objectStore('myStore');
   
    // console.log('Getting value from the database');

    // if (!database) {
    //   console.error('Database not available');
    //   return;
    // }else{
    //   console.log('Database  available');
    // }
    const transaction = database.transaction(['crossword'], 'readwrite');
      // console.log('Transaction created successfully');

      const store = transaction.objectStore('crossword');
      // console.log('Object store accessed successfully');

      const request = store.get(3);

      request.onsuccess = (event) => {
        // console.log(event);
        const value = event.target.result;

        // Update the local state
        dontShowAgainValue = value ? value.value : false;
        // console.log('Value retrieved successfully:', dontShowAgainValue);
        // alert(dontShowAgainValue);
       
      };

      request.onerror = (event) => {
        console.error('Error getting value from the database', event.target.error);
      };
        // Inside getValue function
    // console.log('Getting value from the database');
    // const transaction = database.transaction(['myStore'], 'readonly');
    // console.log('Transaction created successfully');
    // const store = transaction.objectStore('myStore');
    // console.log('Object store accessed successfully');

    // const request = store.get(1);

    //     request.onsuccess = (event) => {
    //       const value = event.target.result;

    //       // Update the local state
    //       storedValue = value ? value.value : '';
    //     };

    //     request.onerror = (event) => {
    //       console.error('Error getting value from the database', event.target.error);
    //     };
    // return dontShowAgainValue;
  };


    // Function to add or update a value in the database
  const setValueInIndexedDB = async () => {
    try {
    const value = localStorage.getItem('dontAskAgainInput');
    // console.log('Retrieved value from localStorage:', value);

    const transaction = database.transaction(['crossword'], 'readwrite');
    const store = transaction.objectStore('crossword');

    // Check if there is an existing record
    const request = store.get(3);

    request.onsuccess = (event) => {
      const existingRecord = event.target.result;

      if (existingRecord) {
        // console.log('Existing record found:', existingRecord);
        // If a record with ID 3 exists, update it
        existingRecord.value = value;
        const updateRequest = store.put(existingRecord);

        updateRequest.onsuccess = (event) => {
          // console.log('Record updated successfully');
        };

        updateRequest.onerror = (event) => {
          console.error('Error updating record:', event.target.error);
        };
      } else {
        // console.log('Record not found. Adding a new one.');
        // If no record with ID 3 exists, add a new one
        const addRequest = store.add({ id: 3, value });

        addRequest.onsuccess = (event) => {
          // console.log('New record added successfully');
        };

        addRequest.onerror = (event) => {
          console.error('Error adding new record:', event.target.error);
        };
      }

      // Update the local state
      dontShowAgainValue = value;
      // console.log('Updated storedValue:', dontShowAgainValue);
    };

    request.onerror = (event) => {
      console.error('Error getting record:', event.target.error);
    };
  } catch (error) {
    console.error('Error in setValue:', error);
  }
  };

  const setValueInCookies = async () => {
    document.cookie = "dontShowAgain=true; SameSite=None; Secure; domain=harwsolutions.com; path=/";
  }

  // Function to get the value of a cookie by its name
  function getValueFromCookies(name){
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie with the specified name is not found
  }

    const authorizeStorageAccess = async () => {
      if (document.hasStorageAccess) {
        try{
          if (await document.hasStorageAccess() == false) {
            // alert("does not have storage access");
            if (document.requestStorageAccess) {
              // alert('await document.requestStorageAccess()');
              await document.requestStorageAccess();
            } else {
              // alert( "requestStorageAccess not available");
            }
          }else {
            // alert( "already has access");
          }
        }
        catch (err) {
          // alert(err+'catch');
        }
      }else {
        // alert( "already has automaticaccess");
        } 
    };

  onMount(async () => {
    try {
      // alert('ok'); 
      // console.log('ok'); 
      // authorizeStorageAccess();
      // This should be triggered by a user gesture, like a click event


      // await document.requestStorageAccess();
      // window.addEventListener('message', handleMessage);

      const request = indexedDB.open('newsgames', 3);
        request.onupgradeneeded = (event) => {
          // alert('2')
          const db = event.target.result;
          // console.log(db);
          if (!db.objectStoreNames.contains('crossword')) {
            // console.log('Creating object store');
          db.createObjectStore('crossword', { keyPath: 'id', autoIncrement: true });
            // var index = store.createIndex("NameIndex", ["name.last", "name.first"]);
          }else{
            console.log('Not Creating object store');
          }
        };
       

        // Handle successful database opening
        request.onsuccess = (event) => {
        
          database = event.target.result;
          // console.log('Database opened');
          // Retrieve the stored value on mount
          getValueFromIndexedDb();
        };

        // Handle errors
        request.onerror = (event) => {
          console.error('Error opening database', event.target.error);
        };
 


      loading = true;
      isInCompleted = false;
      show_alert = false;
      is_puzzle_show = false;
      const response = await axios.get(`${api_path}get-company-url?url=${url}`);
      const res = response.data;
      
      if (res.success == true) {
        data = res.data;
       
        email_signup_url = res.detail.email_signup_url;
        showPermissionPopup = res.detail.permissions_popup;
        if(email_signup_url==''){
          isWordConnectionsShow = true;
        }

        if(showPermissionPopup === true){
          // alert(showPermissionPopup);
        document.addEventListener('click', () => { 
          document.requestStorageAccess().then(
            () => {
              console.log("Access granted");
            },
            () => {
              console.log("Access denied");
            }
          );
        });
      }
        // if(isEmbeded == false){

        //here i am setting local storage when its not in iframe it means user is accessing directly from website.
        const get_run_date=localStorage.getItem('run_date');
        const getUrl=localStorage.getItem('url');
        if( (get_run_date!==res.detail.run_date) || (getUrl!==url)){
            localStorage.removeItem('keyword');
            localStorage.removeItem('timmer');
            localStorage.removeItem('start');
            localStorage.removeItem('w');
            localStorage.removeItem('isKeepTrying');
            localStorage.removeItem('isTryAgainPopup');
            localStorage.removeItem('isReveal');
            localStorage.removeItem('url');
            
        }
        if(getUrl!==url){
    
        }
        localStorage.setItem('run_date',res.detail.run_date);
        localStorage.setItem('url',url);

        //  }
        // res.detail.formated_run_date = changeDateFormat(res.detail.run_date);
        puzzleDetail = res.detail;
        is_puzzle_show = true;
      } else {
        // if(isEmbeded == false){
          localStorage.removeItem('keyword');
        // }
        if (res.is_url_exist == true && res.is_word == false) {
          show_alert = true;
        } else {
          navigate("/error-404", { replace: false });
        }
      
      }
      if (res.style) {
        stylePuzzle = res.style;
      }
      loading = false;
      
    }catch(err){
      // console.log(err);
      //local storage not working
       isIncognitoWindowError = true;
      // window.open('https://newscrossword.org/'+url, '_parent').focus();
      loading = false;
      return false;
    }

    
  });
  function disabled({ detail }) {}
  function checkClues() {
    return clues.map((d) => {
      const index = d.index;
      const cellChecks = d.cells.map((c) => {
        const { value } = cells.find((e) => e.id === c.id);
        const hasValue = !!value;
        const hasCorrect = value === c.answer;
        return { hasValue, hasCorrect };
      });
      const isCorrect =
        cellChecks.filter((c) => c.hasCorrect).length === d.answer.length;
      const isFilled =
        cellChecks.filter((c) => c.hasValue).length === d.answer.length;
      return {
        ...d,
        isCorrect,
        isFilled,
      };
    });
  }

  function reset() {
    isRevealing = false;
    isChecking = false;
    focusedCellIndex = 0;
    focusedDirection = "across";
  }

  function onClear() {

    reset();
    if (revealTimeout) clearTimeout(revealTimeout);
    cells = cells.map((cell) => ({
      ...cell,
      value: "",
    }));
  }

  const toolBarData = (event) => {
    toolBarEvents = event.detail?.toolBarData;
    // console.log(toolBarEvents);
  };
  function onReveal() {
    if (revealed) return true;
    reset();
    cells = cells.map((cell) => ({
      ...cell,
      value: cell.answer,
    }));
    startReveal();
  }

  function onCheck() {
    isChecking = true;
  }

  function redirectToExternal(url){

    if(url!=''){
      window.open(url, '_blank').focus();
    }
    isWordConnectionsShow = true;
  }

  function dontAskAgainFun(event){
    dontAskAgainInput = !dontAskAgainInput;
    setTimeout(() => event.target.checked = dontAskAgainInput, 0);
    localStorage.setItem('dontAskAgainInput',dontAskAgainInput);
    sessionStorage.setItem('dontAskAgainInput', dontAskAgainInput);
    setValueInIndexedDB();
    setValueInCookies();
  }

  function startReveal() {
   
    isRevealing = true;
    isChecking = false;
   
    if (revealTimeout) clearTimeout(revealTimeout);
    revealTimeout = setTimeout(() => {
      isRevealing = false;
    }, revealDuration + 250);
  }

  function onToolbarEvent({ detail }) {
    if (detail === "clear") onClear();
    else if (detail === "reveal") onReveal();
    else if (detail === "check") onCheck();
  }
  function keyEnabled({ detail }) {
    disabled = detail;
  }
  let isClosePopup = true;
  const keepTrying=()=>{
    isClosePopup = false;
    // if(isEmbeded == false){
    localStorage.removeItem("start");
    // }/
    isInCompleted = false;
    toolBarEvents.startTimer();
    toolBarEvents.incrementTimer();
    disabled = false;
    isKeepTryingButtonValue = 'false';
    if(isMobile){
      showKeyboard = true;
    }
   
    // if(isEmbeded == false){
      //these two lines i have added latest at 7/7/2022
    localStorage.setItem('isKeepTrying','false');
    localStorage.setItem('isTryAgainPopup','notShow');
    // }
    // puzzleData.onToolbarEvent({detail:'clear'});
  }


  
</script>

{#if loading == true}
  <Loader bind:loading />
{/if}
{#if validated}
  <section id="mobile" style="{`${
    isEmbeded == false ? 'max-width:825px;margin: auto;' : ''
  }`}">
    <article
      class="svelte-crossword"
      bind:offsetWidth="{width}"
      style="{inlineStyles}"
    >
      <!-- <slot
      name="toolbar"
      onClear="{onClear}"
      onReveal="{onReveal}"
      onCheck="{onCheck}">
      <Toolbar actions="{actions}" on:event="{onToolbarEvent}" />
    </slot> -->
    <!-- && isIpadDevice == false -->
      {#if is_puzzle_show == true }
        <div class="play" class:stacked class:is-loaded="{isLoaded}">
          <Puzzle
            fontFamily="{stylePuzzle.font}"
            cellIndexMap="{cellIndexMap}"
            clues="{clues}"
            focusedCell="{focusedCell}"
            isRevealing="{isRevealing}"
            isChecking="{isChecking}"
            isDisableHighlight="{isDisableHighlight}"
            revealDuration="{revealDuration}"
            showKeyboard="{showKeyboard}"
            stacked="{stacked}"
            isLoaded="{isLoaded}"
            keyboardStyle="{keyboardStyle}"
            puzzleDetail="{puzzleDetail}"
            disabled="{disabled}"
            on:toolBarData="{toolBarData}"
            bind:this="{puzzleData}"
            on:keyEnabled="{keyEnabled}"
            bind:cells
            bind:focusedCellIndex
            bind:focusedDirection
          />

          <Clues
            fontFamily="{stylePuzzle.font}"
            clues="{clues}"
            cellIndexMap="{cellIndexMap}"
            stacked="{stacked}"
            isDisableHighlight="{isDisableHighlight}"
            isLoaded="{isLoaded}"
            bind:focusedCellIndex
            bind:focusedCell
            bind:focusedDirection
          />
        </div>
      {/if}
      {#if isComplete && !isRevealing && showCompleteMessage}
        <CompletedMessage showConfetti="{showConfetti}" isWordConnectionsShow="{isWordConnectionsShow}">
          <slot name="message">
            <h3 class="complete-popup-message-heading margin-top-reduce" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>Well done!</h3>
            <p class="complete-time" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>You solved it in 
              
              <b>{playTime!=undefined?playTime :'0:00'}</b>
            </p>
            <!-- <br /> -->
            <div class="border-line"></div>
            <!-- <br /> -->
            {#if !isWordConnectionsShow && email_signup_url!=''}
            <!--Get Notified buttons Section start-->
            <div class="word-connections-main">
              <p class="word-connections" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>Would you like to get notified each time we publish a new crossword?</p>

              <div class="btns-main-block" style="{isEmbeded ? 'margin-top:20px !important;' : ''}">
                <div class="custom-btns">
                  <button type="button" on:click={event => redirectToExternal(email_signup_url)} class="btn custom-btn-css">Yes, sign me up!</button>
                </div>
                <div class="custom-btns">
                  <button type="button" on:click={ event =>redirectToExternal('')} class="btn custom-btn-css">No, thanks</button>
                </div>
                <div class="custom-btns">
                  <button type="button" on:click={ event =>redirectToExternal('')} class="btn custom-btn-css">I'm already signed up</button>
                </div>
                <div class="custom-btns" style="{isEmbeded ? 'margin-top:17px !important;' : ''}">
                  <input type="checkbox" checked={dontAskAgainInput} on:click|preventDefault={dontAskAgainFun}> <span style="font-size:15px;">Don't ask again</span>
                </div>
              </div>
            </div>
            <!--Get Notified buttons Section end-->
            {/if}
            <div class="word-connections-main {isEmbeded==true ? 'word-connections-main-embedded':''}">
            {#if crossSolvedPuzzle.length && isWordConnectionsShow}
              <p class="word-connections" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>Words tied to the news:</p>
              {#each crossSolvedPuzzle as solvePuzzle}
                <div style="margin-bottom: 19px;">
                  <h3 class="word-name" style="margin-bottom: 0;" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>
                    {solvePuzzle.answer}
                  </h3>
                  <a
                  target="_blank"
                    href="{solvePuzzle.news_url ? solvePuzzle.news_url : '#'}"
                    style="{`color:${
                      stylePuzzle.color ? stylePuzzle.color : '#0d6efd'
                    };`}" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>{solvePuzzle.link_name}</a
                  >
                </div>
              {/each}
            {/if}
            </div><!--word connections main div-->
          </slot>

          
        </CompletedMessage>
      {/if}

      {#if isInCompleted}
        <CompletedMessage
        isOpen="{isClosePopup}"
        keepTrying = "{keepTrying}"
        isTryAgain = {true}
        >
          <slot name="message">
            <h3 class="complete-popup-message-heading" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>Not quite.</h3>
            <p class="complete-time" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>
              The puzzle is filled, but at least one square’s incorrect. 
            </p>
            <br />
            <div style="text-align: center;">
              <button style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} class="keep-btn-style" on:click={keepTrying}>Keep Trying</button>
            </div>
          </slot>
        </CompletedMessage>
      {/if}

      {#if isIncognitoWindowError}
      <CompletedMessage  isOpen="{isClosePopup}" NotIncognito="{false}" style="margin-top:100px;">
        <slot name="message">
          <h3 class="complete-popup-message-heading" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} style="font-size: 25px;"> Chrome Incognito Mode</h3>
          <p class="complete-time" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} style="font-size: 15px;margin-top: -10px;">It looks like you're in Chrome's Incognito mode. To play the crossword when in Incognito mode, please <a href="https://newscrossword.org/{url}" target="_parent">click here</a>, or try again when you're not in Incognito mode.
          </p>
          <!-- <br /> -->
          <!-- <div style="text-align: center;">
            <button style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} class="keep-btn-style" on:click="{() => (isClosePopup = false)}">OK</button>
          </div> -->
        </slot>
      </CompletedMessage>
      {/if}

      <!-- {#if !isIncognitoWindowError && isIpadDevice}
      <CompletedMessage  isOpen="{isClosePopup}" NotIncognito="{false}" style="margin-top:100px;">
        <slot name="message">
          <h3 class="complete-popup-message-heading" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} style="font-size: 25px;">Alert</h3>
          <p class="complete-time" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} style="font-size: 15px;margin-top: -10px;">The crossword isn’t compatible with iPads yet. We are working on an iPad version - please check back soon. In the meantime, you can play the crossword using your phone or computer.
          </p>
          <div style="text-align: center;">
            <button style:font-family={stylePuzzle.font ? stylePuzzle.font : ''} class="keep-btn-style" on:click="{() => (isClosePopup = false)}">OK</button>
          </div>
        </slot>
      </CompletedMessage>
      {/if} -->

      
    </article>
  </section>
{/if}

{#if show_alert == true && is_puzzle_show == false}
  <div
    style="    display: flex;flex-direction: column;  justify-content: center;  height: 90vh;  align-items: center; "
  >
    <h3
      style="text-transform: capitalize;  font-weight: 700;  color: #939191;    text-align: center;" style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}
    >
     No crossword right now. Please check again later.
    </h3>
    {#if isEmbeded == false}
    <div style="text-align: center; margin-top: 31px;">
      <Link to="/" style="text-decoration: none!important;"
        ><span class="button-style " style:font-family={stylePuzzle.font ? stylePuzzle.font : ''}>Back to site</span></Link
      >
    </div>
    {/if}
  </div>
{/if}

<style>
  .custom-btn-css:focus,.custom-btn-css:active {
   outline: none !important;
   box-shadow: none;
}
  .custom-btn-css{
    border: 1px solid black;
    width: 100%;
    padding: 10px 0px;
    border-radius: 50px;
  }
  .btns-main-block{
    margin-top:30px; 

  }
  .custom-btns{
    margin-top: 20px;
  }
  .word-connections{
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
  }
  .word-connections-main{
    height: 100%;
    max-height: 390px;
    overflow: scroll;
    /* border-right: 2px solid #d1d1d1; */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.word-connections-main::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
  
  article {
    position: relative;
    background-color: transparent;
    font-size: 16px;
  }
  .keep-btn-style {
    background: white;
    padding: 12px;
    padding-left: 32px;
    padding-right: 32px;
    text-decoration: none !important;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    border: 0.75px solid #000000;
    border-radius: 23.5px;
    color: #000000;
  }
  .word-name {
    font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;

    color: #000000;
  }
  .border-line {
    border-bottom: 0.75px solid #b6b6b6;
    margin-bottom: 15px;
  }
  .complete-popup-message-heading {
    font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    color: #000000;
  }
  .margin-top-reduce{
    margin-top: -10px !important;
  } 
  .complete-time {
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 15px;
    color: #000000;
  }

  .play {
    display: flex;
    flex-direction: var(--order, row);
  }

  .play.is-loaded.stacked {
    flex-direction: column;
  }

  h3 {
    margin: 0;
    margin-bottom: 0.5em;
  }


  @media only screen and (max-width: 870px) {
    .word-connections-main{
      /* max-height: 300px; */
      max-height: 310px;
    }
  }

  @media only screen and (max-width: 720px) {
    .play:not(.is-loaded) {
      flex-direction: column;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .word-connections-main-embedded{
      max-height: 320px !important;
    }
    .word-connections-main{
      max-height: 460px;
    }

  }
</style>
